import React from "react";
import images from "../assets/images";
import ReactPlayer from "react-player";
import TrackVisibility from "react-on-screen";


import LazyLoad from "react-lazy-load";

import Partner from "./Partner";

const Home = () => {
  return (
    <div className="ksand font-montserrat text-xl text-white shadow-yellow-300">
      <div className="mx-auto mt-48 flex h-full max-h-screen w-full max-w-[1200px] flex-col items-center justify-center rounded-2xl pb-10 text-center max-sm:mt-48 md:mt-48 lg:mt-80">

        <p className="sm:text-md mt-96 pt-12 animate-pulse text-sm text-[#f2d383] max-sm:mb-32 md:text-lg lg:text-xl xl:text-2xl font-bold">
          "Embrace Harmony and Rediscover Balance"
        </p>
      </div>

      <div className=" md:flex-no-wrap mx-auto mt-48 flex max-w-[1200px]  flex-wrap rounded-3xl backdrop-blur-xl bg-black/20  bg-opacity-90 mb-10 shadow-2xl shadow-black  sm:mt-20">
        <div className="w-full p-10 md:w-[1200px] border rounded-3xl events font-semibold  border-[#c7b654] ">
          <h1 className="text-center font-montserrat  max-sm:text-lg sm:text-lg md:text-2xl lg:text-3xl">
            "Find Your Balance at Back to Balance: The Ultimate Yoga Festival"
          </h1>{" "}
          <br />
          <p className="pt-5 px-5 lg:px-12 text-center font-montserrat  max-sm:text-sm ">
            Back to Balance is a yoga festival that offers a variety of yoga
            styles, meditation, and educational workshops. The festival aims to
            help people deepen their yoga practice and cultivate mindfulness. In
            addition, there are health and wellness vendors, live music, and
            healthy food options. Join us to celebrate health, wellness, and
            community with like-minded individuals. Come find balance in your
            life!
          </p>
          <br />
        </div>
      
      </div>
      <div className="w-full p-2 md:w-[1200px] tems-center justify-center flex mx-auto backdrop-blur-xl bg-black/20 border rounded-3xl border-[#c7b654] ">
          <img
            src={images.about}
            alt="gathering"
            className="h-auto w-full  rounded-2xl "
          />
        </div>
      <div className="mx-auto mt-40 border-[#c7b654] border h-auto w-full events font-semibold max-w-[1200px] flex-col items-center justify-center rounded-2xl backdrop-blur-xl bg-black/20 pt-10 text-center shadow-2xl shadow-black  max-sm:p-5 ">
        <h1 className="ksand  text-center font-montserrat hover:translate-x-3 hover:scale-110  hover:text-yellow-300 max-sm:text-lg sm:text-lg md:text-xl lg:text-2xl">
          {" "}
          bring your open heart and your willingness to explore new ways of
          being.
        </h1>{" "}
        <br />
        <p className="ksand px-10 font-montserrat max-sm:p-0 max-sm:text-sm md:mb-10">
          We have carefully curated a lineup of workshops, classes, and
          activities that are designed to help you find balance and peace within
          yourself. From yoga and meditation to sound healing and dance, there
          is something for everyone.
        </p>
     
      </div>
      <div className="max-w-[1200px] items-center justify-center flex mx-auto mt-20 rounded-3xl backdrop-blur-xl bg-black/20 lg:py-10 ">
      <TrackVisibility partialVisibility once>
          {({ isVisible }) => (
            <LazyLoad height={700} offset={300} className="max-sm:!h-96">
              <ReactPlayer
                className="react-player"
                width="100%"
                height="100%"
                url={images.video}
                controls
                playing={false}
                loop={false}
              />
            </LazyLoad>
          )}
        </TrackVisibility>
      </div>
      <Partner />
    </div>
  );
};

export default Home;

import React, { useState, useContext } from "react";
import TrackVisibility from "react-on-screen";
import { Row, Col, Button, Navbar, Modal } from "react-bootstrap";
import { productsArray } from "./productsStore";
import ProductCard from "./ProductCard";
import { CartContext } from "./CartContext";
import CartProduct from "./CartProduct";
import "./Ticket.css";

const Ticket = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cart = useContext(CartContext);

  const checkout = async () => {
    await fetch("http://localhost:4000/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: cart.items }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.url) {
          window.location.assign(response.url); // Forwarding user to Stripe
        }
      });
  };

  const productCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  return (
    <>
      <div
      >
        <Navbar expand="sm" variant="dark" className="mx-auto">
          <Navbar.Brand className="mx-auto">
            Book a Session{" "}
          </Navbar.Brand>
          <Button 
            variant="success"
            onClick={handleShow}
          >
            Cart ({productCount} Items)
          </Button>
        </Navbar>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Your Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {productCount > 0 ? (
              <>
                <p>Items in your cart:</p>
                {cart.items.map((currentProduct, idx) => (
                  <CartProduct
                    key={idx}
                    id={currentProduct.id}
                    quantity={currentProduct.quantity}
                  >
                    {" "}
                  </CartProduct>
                ))}
                <h1>Total: {cart.getTotalCost().toFixed(2)}</h1>
                <button onClick={checkout}>
                  Complete Your Order!
                </button>
              </>
            ) : (
              <h1>There are no itmes in your cart!</h1>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Row xs={1} md={2} lg={4} className="g-4">
          {productsArray.map((product, idx) => (
            <Col align="center" key={idx}>
              <TrackVisibility partialVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__flipInY" : ""
                    }
                  >
                    <ProductCard product={product} />
                  </div>
                )}
              </TrackVisibility>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Ticket;

import React from "react";

const Impressum = () => {
  return (
    <div
      className="mx-auto flex items-start justify-center bg-black bg-opacity-50 p-10 pt-[96px] text-start text-white "
      id="privacy"
    >
      <div className=" flex max-w-[800] flex-col rounded-2xl text-center">
        <h1 className="hover: font-montserrat text-4xl hover:translate-y-2 hover:text-yellow-300 sm:text-6xl md:py-6 md:text-7xl">
          Back to Balance
        </h1>
        <p className="text-md text-left my-16 font-quicksand">
          <h1 className="text-xl">Privacy Policy</h1>
          <br />
          Effective Date: 2023.07.01 <br />
          <br />
        <p>  At Back to Balance, we are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy explains how we collect, use, disclose, and protect the
          information you provide to us when you visit our website or use our
          services. Please read this Privacy Policy carefully before using our
          website or providing any personal information to us.</p>
          <br />
          <h2>Information We Collect </h2>
          <br />
          <p>We collect only the necessary information from you, specifically your
          email address. We do not collect any other personal information unless
          you voluntarily provide it to us.</p> 
          <br />
          Legal Basis for Processing Personal Data <br />
          <br />
          We use the email addresses we collect for the following purposes:{" "} <br />
          <br />
          <p>Communication: We may use your email address to communicate with you,
          respond to your inquiries, and provide the information or services you
          request from us. </p>
          <br />
          <p>
          Marketing: With your consent, we may use your email address to send
          you newsletters, updates, or other promotional materials about our
          products, services, or events. You have the right to opt out of
          receiving such communications at any time. 
          </p>
          <br />
          Data Retention <br />
          <br />
          <p>We retain your email address for as long as necessary to fulfill the
          purposes outlined in this Privacy Policy or as required by law. Once
          the purpose for which we collected your email address is no longer
          applicable, we will securely delete or anonymize your data.</p>
      
          <br />
          <p>  Data Sharing and Disclosure <br />
          We do not share or disclose your email address to any third parties
          unless required by law or with your explicit consent. We do not sell,
          trade, or rent your personal information to any external parties.{" "}</p>
          <br />
          <p>Data Transfers <br />
          If you are located outside the European Economic Area (EEA) and choose
          to provide your email address to us, please be aware that we may
          transfer your data to servers located within the EEA for processing
          and storage. By providing us with your email address, you consent to
          this transfer.</p>
          <br />
          <p>Data Security <br />
          We have implemented appropriate technical and organizational measures
          to safeguard your email address and ensure the security of your
          personal data. These measures are designed to prevent unauthorized
          access, disclosure, alteration, or destruction of your information.
          However, please be aware that no method of transmission over the
          internet or electronic storage is completely secure, and we cannot
          guarantee absolute security. </p>
          <br />
          
          <p>Your Rights <br />Under the GDPR, you have certain rights regarding your personal data.
          These include the right to: <br />
          Access and obtain a copy of your personal data held by us. <br />
          Request the correction or erasure of your personal data. <br />
          Object to or restrict the processing of your personal data. <br />
          Withdraw your consent at any time, where we rely on your consent for
          processing your personal data. <br /></p>
         <p> Lodge a complaint with a supervisory authority if you believe we have
          violated your rights under applicable data protection laws. <br /></p> 
          <br />
          <p>Contact Us <br />
          If you have any questions or concerns about our Privacy Policy or the
          handling of your personal information, please contact us at
          backtobalance-festival@gmail.com We will respond to your inquiries as
          soon as possible. <br /></p>
          By using our website or providing your email address, you acknowledge
          that you have read and understood this Privacy Policy and agree to its
          terms and conditions. <br />
          Note: This is a sample privacy policy and should be reviewed and
          customized according to your specific business practices and legal
          requirements. It is advisable to seek legal counsel to ensure
          compliance with applicable privacy laws and regulations in your
          jurisdiction. <br />
        </p>
      </div>
    </div>
  );
};

export default Impressum;

import { useState } from "react";
import images from "../assets/images";
import { AnimatePresence, motion } from "framer-motion";
import logo from "../assets/logo/Back To Balance Festival LOGO Black.png"
import LazyLoad from "react-lazy-load";
const Participate = () => {
  // const backgroundImageStyle = {
  //   backgroundImage: `url(${bg})`, // Replace with your image path
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  // };
  const pictures = [
    {
      id: 1,
      title: "Art, Deco & Video",
      imageUrl: images.night,
      description:
        "Calling all artists, decorators, and videographers! Join our festival and showcase your creativity. Apply now to be a part of an immersive art experience!",
    },
    {
      id: 2,
      title: "Music",
      imageUrl: images.dj,
      description:
        "Join the rhythm of the festival. Amplify your music. Inspire hearts and ignite souls. Apply now to be a part of our musical extravaganza. Let your melodies soar, connecting with a crowd hungry for the magic only music can bring. Unleash your passion and make memories that will resonate forever. The stage is calling",
    },
    {
      id: 3,
      title: "Bazar - Market",
      imageUrl: images.market,
      description:
        "Experience a world of artisanal wonders at our festival market. Unleash your creativity and showcase your unique treasures. Join fellow vendors, connect with shoppers, and leave a lasting impression with your captivating creations. The market is calling, be a part of its vibrant tapestry.",
    },
    {
      id: 4,
      title: "Food Vendors",
      imageUrl: images.drinks,
      description:
        "Indulge in our festival's Food & Drink category! Show off your culinary skills with delectable dishes and refreshing beverages. Join us now and leave a lasting impression on festival-goers. Apply today and make your mark in the world of food and drink at our festival!",
    },
    {
      id: 5,
      title: "Performances",
      imageUrl: images.sing,
      description:
        "Experience the magic of live performances at our festival! From captivating acts to mesmerizing shows, our stage is ready to showcase your talent. Join us and be a part of the unforgettable performances that will leave the audience in awe. Apply now to take center stage and leave your mark on our festival!",
    },
    {
      id: 6,
      title: "Therapists",
      imageUrl: images.heal,
      description:
        "Heal hearts and minds as a festival therapist. Join us and offer your transformative services to festival-goers. From soothing massages to rejuvenating energy work, make a profound impact on their well-being. Apply now to join our healing journey and help attendees find inner balance and serenity.",
    },
    {
      id: 7,
      title: "Volunteer",
      imageUrl: images.exit,
      description:
        "Join as a festival volunteer and make a difference! Help us create an unforgettable experience for all attendees. From event setup to guiding guests, your contribution matters. Embrace community, create memories, and be a vital part of our vibrant festival. Apply now to join our team of dedicated volunteers!",
    },
    {
      id: 8,
      title: "Sponsors",
      imageUrl: images.area,
      description:
        "Be a festival sponsor and boost your brand! Get seen by thousands of festival-goers and enjoy exclusive perks. Contact us now to discuss sponsorship opportunities. Let's make your brand shine at our festival!",
    },
  ];
  const [expandedId, setExpandedId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };


  const handleCardClick = (id) => {
    setExpandedId(id === expandedId ? null : id);
  };
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  return (
    <div className="mx-auto flex w-full flex-col items-center justify-center rounded-xl p-5 pb-20 pt-32 text-center">
          {showPopup && (
        <div className="homepage hidden z-50 rounded-xl px-14 fixed top-0 h-full w-full items-center justify-center lg:flex backdrop-blur-3xl transition-all duration-500  ease-in-out hover:bg-[#20202066]  ">
          <div className="popup">
            {/* Content for the popup */}

            <iframe
              src="https://form.jotform.com/232055818196057"
              title="Typeform Popup"
              width={1300}
              height={700}
              className="pt-10"
            />
            <button
              className=" delay-50 hover:-translate mb-20 mt-5 rounded-full bg-amber-100 px-4 py-2 font-montserrat font-bold text-amber-800 transition duration-300 ease-in-out hover:scale-125 hover:bg-green-400"
              onClick={closePopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {showPopup && (
      
          <div className="popup w-full fixed left-0 lg:hidden top-0 py-32 z-50 h-full items-center justify-center backdrop-blur-xl bg-black bg-opacity-40   ">
            {/* Content for the popup */}

            <iframe
              src="https://form.jotform.com/232055818196057"
              title="Typeform Popup"
              className="w-full h-full"
            />
            <button
              className=" delay-50 hover:-translate mb-20 mt-5 rounded-full bg-amber-100 px-4 py-2 font-montserrat font-bold text-amber-800 transition duration-300 ease-in-out hover:scale-125 hover:bg-green-400"
              onClick={closePopup}
            >
              Close
            </button>
          </div>
        
      )}
      <div className="w-full max-w-screen-2xl rounded-xl px-4 pb-20 md:px-8">
     
        <div className="mb-4 flex items-center justify-center events  shadow-black shadow-2xl sm:mb-8 md:mb-12 font-semibold backdrop-blur-2xl p-10 rounded-full">
         
          <div className="mb-10 flex max-w-[1100px] flex-col items-center gap-12">
            <h2 className=" font-montserrat  hover:translate-y-2 hover:text-yellow-300 sm:text-xl md:text-2xl lg:text-4xl">
              Participate
            </h2>
            <p className="text-md  font-montserrat sm:text-lg md:text-2xl font-bold">
            Welcome to the Back to Balance Festival family! Your presence as artists, volunteers, and sponsors enriches our diverse tapestry. Let's co-create a transformative space where passion and creativity converge. <br /> " Thank you for joining this unforgettable journey "
            </p>
          </div>
          
        </div>
          <img src={images.handsup} alt="hands" className="rounded-full mb-20 border border-black shadow-2xl shadow-black brightness-150" />


        <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 ">
          {pictures.map((pictures) => (
            <motion.div
              key={pictures.id}
              layoutId={pictures.id}
              onClick={() => handleCardClick(pictures.id)}
            >
              <a className="group relative flex h-48 rounded-full border border border-yellow-500 cursor-pointer participate2 items-center justify-center overflow-hidden  md:h-[370px]">
                <LazyLoad height={200} offset={300}>
                  <motion.img
                    src={logo}
                    alt="pictures"
                    className="absolute scale-50 inset-0 h-full w-full object-contain  object-center  duration-300 group-hover:scale-100 group-hover:brightness-50"
                  />
                </LazyLoad>
                <div className="pointer-events-none  absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-90"></div>
                <motion.h2
                  className="text-extrabold relative backdrop-blur-xl  inline-block rounded-full  px-5 font-montserrat font-extrabold group-hover:scale-110 group-hover:text-yellow-200 sm:text-lg md:text-xl lg:text-3xl "
                  initial={false}
                  animate={{ opacity: expandedId === pictures.id ? 0 : 1 }}
                  
                >
                  {pictures.title}
                </motion.h2>

                <AnimatePresence >
                  {expandedId === pictures.id && (
                    <div key="content" >
                      <motion.div
                        key="content"
                        className="fixed inset-0  z-10 m-6 flex items-center justify-center p-4 sm:m-10 sm:p-5 md:m-14 md:p-16 lg:m-20 lg:p-20 xl:m-24 xl:p-28 "
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                      >
                     

                        <motion.h2 className="relative backdrop-blur-3xl participate2 py-20 px-5 md:p-20 lg:p-40 rounded-full mb-3 inline-block justify-center text-center font-montserrat text-xl text-white sm:text-xl md:ml-5 md:text-4xl">
                          {pictures.description}
                        <div className="pointer-events-none rounded-full  absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-90"></div>
                        </motion.h2>
                      </motion.div>
                    </div>
                  )}
                </AnimatePresence>
              </a>
            </motion.div>
          ))}
        </div>
      </div>
      <button
      className="button1 font-bold"
        onClick={openPopup}
      >
        Apply Here
      </button>
    </div>
  );
};

export default Participate;

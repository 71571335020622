import { Disclosure } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const navigation = [
  { name: "HOME", href: "home", current: false },
  { name: "EVENTS", href: "events", current: false },
  { name: "PROGRAM", href: "program", current: false },
  { name: "GALLERY", href: "gallery", current: false },
  { name: "PARTICIPATE", href: "participate", current: false },
  // { name: "BUSINESS BALANCE", href: "business", current: false },
  // { name: 'TICKET', href: 'ticket', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout() {
  const animateMenu = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: "-100%" },
  };

  return (
    <Disclosure as="nav" className="sticky top-0 z-50 bg-rgba(253,187,45,1) backdrop-blur-3xl  ">
      {({ open, close }) => (
        <>
          <div className="mx-auto flex justify-center px-2 max-sm:justify-normal  sm:px-6 lg:px-8 ">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-black rounded-full hover:text-[#caa26d] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#caa26d]">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-8 text-sm font-montserrat font-semibold">
                    {navigation.map((item, idx) => (
                      <Link
                        key={idx}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900  text-black"
                            : " hover:bg-black  text-black max-lg:text-xs",
                          " rounded-full px-2 py-2  duration-300  hover:text-[#c7b654] "
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="justify-center backdrop-blur-2xl pb-96 pt-16 text-center font-montserrat font-bold sm:hidden rounded-2xl  ">
            <AnimatePresence>
              {open && (
                <motion.div
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={animateMenu}
                  className="space-y-5 px-2 pb-3 pt-2"
                >
                  {navigation.map((item, idx) => (
                    <Link
                      onClick={close}
                      key={idx}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-[#fcf8dc]"
                          : "text-black hover:text-[#caa26d] shadow-xl py-5 hover:translate-y-1 hover:translate-x-1 transition-all duration-200",
                        "block rounded-full px-3 py-2 text-base font-semibold"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}


const productsArray = [
    {
        id: "1",
        title: "Day Ticket Saturday",
        price: 59,
        
    },
    {
        id: "2",
        title: "Day Ticket Sunday",
        price: 120
    },
    {
        id: "3",
        title: "Full Weekend Ticket",
        price: 99
    }

];

function getProductData(id) {
    let productData = productsArray.find(product => product.id === id);

    if (productData == undefined) {
        console.log("Product data does not exist for ID: " + id);
        return undefined;
    }

    return productData;
}

export { productsArray, getProductData };
import React, { useState } from "react";
import images from "../assets/images";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

const Partner = () => {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  return (
    <>
      <div className="mx-auto border-[#c7b654] border font-semibold events backdrop-blur-xl bg-black/20 mt-20 flex max-w-[1200px] flex-col items-center  justify-center rounded-2xl bg-black bg-opacity-90 p-10  text-center font-montserrat shadow-2xl shadow-black max-sm:w-full">
        {showPopup && (
          <div className="homepage fixed top-0 z-50 hidden h-full w-full items-center justify-center rounded-xl px-14 backdrop-blur-3xl transition-all duration-500 ease-in-out  hover:bg-[#20202066] lg:flex  ">
            <div className="popup">
              {/* Content for the popup */}

              <iframe
                src="https://form.jotform.com/232046233159047"
                title="Typeform Popup"
                width={1300}
                height={700}
                className="pt-10"
              />
              <button
                className=" delay-50 hover:-translate mb-20 mt-5 rounded-full bg-amber-100 px-4 py-2 font-montserrat font-bold text-amber-800 transition duration-300 ease-in-out hover:scale-125 hover:bg-green-400"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {showPopup && (
          <div className="popup fixed left-0 top-0 z-50 h-full w-full items-center justify-center bg-black bg-opacity-40 py-32 backdrop-blur-xl lg:hidden   ">
            {/* Content for the popup */}

            <iframe
              src="https://form.jotform.com/232046233159047"
              title="Typeform Popup"
              className="h-full w-full"
            />
            <button
              className=" delay-50 hover:-translate mb-20 mt-5 rounded-full bg-amber-100 px-4 py-2 font-montserrat font-bold text-amber-800 transition duration-300 ease-in-out hover:scale-125 hover:bg-green-400"
              onClick={closePopup}
            >
              Close
            </button>
          </div>
        )}
        <h1 className="py-5 text-2xl ">Become a Partner</h1> <hr /> <br />
        <h2 className="mb-5 text-lg">
          Elevate Your Brand with{" "}
          <a
            className="hover:text-yellow-500"
            href="https://www.morielement.com"
            target="_blank"
            rel="noreferrer"
          >
            Mori Element's
          </a>{" "}
          Back to Balance Festival!{" "}
        </h2>
        <p className="mb-10 p-10 max-sm:p-0 max-sm:text-sm">
          Unlock a world of wellness and conscious living as a distinguished
          sponsor of our transformative Back to Balance Festival. With a
          year-round presence on our website, your brand will resonate with
          health-conscious individuals and yoga enthusiasts alike. Embrace a new
          era of engagement, exclusivity, and meaningful connections.
        </p>
        <Accordion className="w-full px-20 max-sm:px-0" allowMultiple>
          <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
            <h2>
              <AccordionButton className="flex justify-between">
                <span
                  className="text-navy-900 text-left font-montserrat  dark:text-white"
                  flex="1"
                  textalign="left"
                >
                  Your Benefits as a Sponsor
                </span>
                <AccordionIcon className="!text-navy-900 text-left dark:!text-white" />
              </AccordionButton>
            </h2>
            <AccordionPanel
              className="text-medium !text-navy-900 mt-2 text-left dark:!text-white"
              pb={4}
            >
              <ul className="list-disc max-sm:text-sm">
                <li>
                  ✨ Prime Website Placement: Your logo showcased prominently on
                  our site, reaching a diverse and engaged audience.
                </li>
                <li>
                  ✨ Year-long Visibility: Public and private events ensure
                  continuous brand exposure throughout the year.
                </li>
                <li>
                  ✨ Authentic Alignment: Forge a deep connection with our
                  community that values holistic health and well-being.
                </li>
                <li>
                  ✨ Collaborative Promotion: Engage with our social platforms,
                  amplifying your brand's reach and resonance.
                </li>
                <li>
                  ✨ Access to Workshops: Delve into transformative experiences
                  with world-renowned teachers and healers.
                </li>
                <li>
                  ✨ Impactful Investment: A mid-price package offering
                  unmatched value for your commitment.
                </li>
              </ul>
            </AccordionPanel>
          </AccordionItem>{" "}
          <AccordionItem className="border-b border-gray-200 py-[17px] dark:!border-white/10">
            <h2>
              <AccordionButton className="flex justify-between">
                <span
                  className="text-navy-900 text-left font-montserrat  dark:text-white"
                  flex="1"
                  textalign="left"
                >
                  FAQ
                </span>
                <AccordionIcon className="!text-navy-900 text-left dark:!text-white" />
              </AccordionButton>
            </h2>
            <AccordionPanel
              className="text-medium !text-navy-900 mt-2 text-left dark:!text-white"
              pb={4}
            >
              <ul className="max-sm:text-sm">
                <li>
                  Q: What is the Back to Balance Festival, and what does it
                  entail? <br /> <hr />
                  <br />
                  A: The Back to Balance Festival is an annual transformative
                  event hosted by Mori Element. It brings together wellness
                  enthusiasts, yoga practitioners, and conscious living
                  advocates to promote holistic well-being, mindfulness, and
                  healthy living through various workshops, classes, and
                  activities.
                </li>
                <li>
                  {" "}
                  <br />
                  Q: How can my brand benefit from sponsoring the Back to
                  Balance Festival? <br /> <hr />
                  <br />
                  A: Sponsoring the festival offers your brand a year-round
                  presence on our website, increased visibility to
                  health-conscious individuals and yoga enthusiasts, engagement
                  with a targeted audience, exclusivity through our partnership,
                  and meaningful connections with like-minded individuals.
                </li>{" "}
                <br />
                <li>
                  Q: Can you provide insights into the demographic profile of
                  the festival attendees? <br /> <hr />
                  <br />
                  A: Our festival attracts a diverse audience, predominantly
                  composed of health-conscious individuals, yoga practitioners,
                  and individuals seeking personal growth and wellness. The
                  attendees generally span across different age groups,
                  geographic locations, and have a strong interest in conscious
                  living and well-being.
                </li>{" "}
                <br />
                <li>
                  Q: How will Mori Element promote and market the festival?{" "}
                  <hr />
                  <br />
                  A: We have a robust marketing and promotion plan that includes
                  targeted social media campaigns, email marketing to our
                  subscriber base, press coverage, collaboration with
                  influencers and wellness bloggers, and strategic partnerships
                  with other organizations to ensure maximum visibility for the
                  festival.
                </li>
                <br />
                <li>
                  Q: Are there any exclusive sponsorship opportunities
                  available? <br /> <hr />
                  <br />
                  A: Yes, we offer exclusive sponsorship opportunities for
                  brands looking to make a significant impact at the festival.
                  This may include sponsoring specific events or workshops,
                  hosting branded activities, or other customized activations to
                  elevate your brand's presence.
                </li>
                <br />
                <li>
                  Q: What are the payment methods and deadlines for sponsorship
                  commitments? <br /> <hr />
                  <br />
                  A: Payment methods and deadlines will be outlined in the
                  sponsorship agreement. Our team will work closely with you to
                  ensure a seamless payment process and meet any specific
                  requirements you may have.
                </li>
              </ul>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <div>
          <button
            onClick={openPopup}
            className="delay-50 hover:-translate serrat mt-10  w-full rounded-full bg-amber-100 px-4 py-2 font-montserrat text-amber-800 transition duration-300 ease-in-out hover:scale-125 hover:bg-green-400 max-sm:text-sm"
          >
            Apply for Sponsorship
          </button>
        </div>
      </div>
      <div className="mx-auto mb-20 border-[#c7b654] events border  mt-20 flex max-w-[1200px] flex-col items-center justify-center rounded-2xl bg-black/20  p-10 text-center shadow-2xl shadow-black backdrop-blur-xl max-sm:w-full">
        <h1 className="pb-10 font-semibold">Our Partners</h1>
        <div className="flex flex-wrap gap-14 max-sm:flex-col">
          <div className="group rounded-full shadow-lg backdrop-blur-lg bg-black backdrop-brightness-75 backdrop-saturate-150 duration-300 hover:scale-125 hover:shadow-yellow-100 focus:shadow-yellow-100">
            {" "}
            <a
              href="https://www.morielement.com"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                src={images.morielement}
                className="h-24 w-48 "
                alt="morielement"
              />
            </a>{" "}
          </div>

      
          <div className="max-sm:flex max-sm:justify-center">
            {" "}
            <a href="https://www.instagram.com/spiritbase_festival/" target="_blank" rel="noreferrer">
              {" "}
              <img
                className="group h-24 w-24 rounded-2xl shadow-lg    backdrop-blur-lg backdrop-brightness-75 backdrop-saturate-150 duration-300 hover:scale-125 hover:shadow-yellow-100 focus:shadow-yellow-100"
                src={images.spirit}
                alt="spiritbase festival"
              />
            </a>{" "}
          </div>
          <div className="max-sm:flex max-sm:justify-center">
            {" "}
            <a href="https://www.cbdario.com" target="_blank" rel="noreferrer">
              {" "}
              <img
                className="group h-24 w-24 rounded-full shadow-lg    backdrop-blur-lg backdrop-brightness-75 backdrop-saturate-150 duration-300 hover:scale-125 hover:shadow-yellow-100 focus:shadow-yellow-100"
                src={images.cbd}
                alt="cbdario"
              />
            </a>{" "}
          </div>

          <div className="max-sm:flex max-sm:justify-center">
            {" "}
            <a href="https://amwasser.wien/" target="_blank" rel="noreferrer">
              {" "}
              <img
                className="group h-24 w-24 rounded-2xl bg-white    shadow-lg backdrop-blur-lg backdrop-brightness-75 backdrop-saturate-150 duration-300 hover:scale-125 hover:shadow-yellow-100 focus:shadow-yellow-100"
                src={images.usus}
                alt="usus"
              />
            </a>{" "}
          </div>
          <div className="max-sm:flex max-sm:justify-center">
            {" "}
            <a href="https://bwdxb.com/" target="_blank" rel="noreferrer">
              {" "}
              <img
                className="group h-24 w-24 rounded-2xl bg-white    shadow-lg backdrop-blur-lg backdrop-brightness-75 backdrop-saturate-150 duration-300 hover:scale-125 hover:shadow-yellow-100 focus:shadow-yellow-100"
                src={images.wings}
                alt="bluewings"
              />
            </a>{" "}
          </div>

          <div className="max-sm:flex max-sm:justify-center">
            {" "}
            <a
              href="https://www.dattelbaer.at"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                className="group h-24 w-24 rounded-full bg-white    object-contain shadow-lg backdrop-blur-lg backdrop-brightness-75 backdrop-saturate-150 duration-300 hover:scale-125 hover:shadow-yellow-100 focus:shadow-yellow-100"
                src={images.dattel}
                alt="dattelbaer"
              />{" "}
            </a>
          </div>
          <div className="max-sm:flex max-sm:justify-center">
            {" "}
            <a
              href="https://www.backtobalance-festival.com"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                className="group h-24 w-24 rounded-full bg-white    object-contain shadow-lg backdrop-blur-lg backdrop-brightness-75 backdrop-saturate-150 duration-300 hover:scale-125 hover:shadow-yellow-100 focus:shadow-yellow-100"
                src={images.purjahan}
                alt="pourjahani"
              />{" "}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Partner;

import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import NoPage from "./pages/NoPage";
import Events from "./pages/Events";
import Program from "./pages/Program";
import logo from "./assets/logo/Back To Balance Festival LOGO Black.png"
import History from "./pages/History";
import Business from "./pages/Business"
import { motion} from "framer-motion";
import Partner from "./pages/Partner";
import Ticket from "./Store/Ticket";
import Home from "./pages/Home";
import Participate from "./pages/Participate";
import Footer from "./components/Footer";
import Impressum from "./pages/Impressum";
import Privacy from "./pages/Privacy"


function App() {

  return (
    <>
      
      <div className="pointer-events-none   fixed left-1/2 top-1/2 z-[-1] flex -translate-x-1/2 max-sm:scale-150 -translate-y-1/2 transform items-center justify-center whitespace-nowrap opacity-100">
        <motion.div
        initial={{scale: 0.1 , opacity: 0.1}}
        animate={{scale:1,opacity:1,rotate:[0,360]}}
        transition={{
          duration: 0.1,
          type:"spring",
          damping:50,
          stiffness:100
        }}
        
        
        >
         
          <img id="logo" loading="lazy"  src={logo} alt="logo1" width="600px" height="600px" className="sm:scale-150 md:scale-100 lg:scale-125 xl:scale-100 max-sm:scale-100 rounded-full shadow-2xl border border-yellow-600 shadow-black" />
     
        </motion.div>
      </div>

      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route exact path="/Program" element={<Program />} />
        <Route exact path="/Gallery" element={<History />} />
        <Route exact path="/participate" element={<Participate />} />
        <Route exact path="/ticket" element={<Ticket />} />
        <Route exact path="/impressum" element={<Impressum/>} />
        <Route exact path="/privacy" element={<Privacy/>} />
        <Route exact path="/business" element={<Business/>} />
        <Route exact path="/partner" element={<Partner/>} />
        <Route exact path="/events" element={<Events/>} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import React from "react";

const Footer = () => {


  return (
    <footer className="max-w-[1200px] mx-auto rounded-t-3xl events bg-black/20 backdrop-blur-3xl shadow-2xl shadow-black text-center text-white bottom-0 ">
      <div className="mx-auto py-6 font-quicksand ">
        <div className="mx-auto mb-6 flex max-h-screen w-full max-w-full items-center justify-center text-center ">
          <a
            href="https://www.facebook.com/people/Back-to-Balance-Festival/100083363437815/"
            type="button"
            target="_blank"
            rel="noreferrer" 
            className="m-1 h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 hover:text-[#4ec612] focus:outline-none focus:ring-0 "
            data-te-ripple-init
            data-te-ripple-color="light"
            aria-label="facebook"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto h-full w-4"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/backtobalance_festival/"
            target="_blank"
            rel="noreferrer" 
            type="button"
            className="m-1 hover:text-[#4ec612] h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
            data-te-ripple-init
            data-te-ripple-color="light"
            aria-label="instagram"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto h-full w-4"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </a>

          <a
            href="https://www.linkedin.com/in/morteza-ketabnevis-08ab7380/"
            target="_blank"
            rel="noreferrer" 
            type="button"
            className="m-1 hover:text-[#4ec612] h-9 w-9 rounded-full border-2 border-white uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0"
            data-te-ripple-init
            data-te-ripple-color="light"
            aria-label="linkedin"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto h-full w-4"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
            </svg>
          </a>
        </div>


        

        <div className="grid md:grid-cols-2 lg:grid-cols-4">
          <div className="mb-6">
          <h1 className="mb-2.5 font-bold uppercase">Socials</h1>
            <ul className="list-none mb-5">
              <li className="py-2">
                <a
                  href="https://www.facebook.com/people/Back-to-Balance-Festival/100083363437815/"
                  className="text-white hover:text-[#4ec612]"
                  aria-label="facebook"
                >
                  Facebook
                </a>
              </li>
              <li className="py-2">
                <a
                  href="https://www.instagram.com/backtobalance_festival/"
                  className="text-white hover:text-[#4ec612]"
                  aria-label="instagram"
                >
                  Instagram
                </a>
              </li>
              <li className="py-2">
                <a
                  href="https://www.youtube.com/@backtobalancefestival594"
                  className="text-white hover:text-[#4ec612]"
                  aria-label="youtube"
                >
                  Youtube
                </a>
              </li>
            </ul>
          </div>

          <div className="mb-6">
            <h1 className="mb-2.5 font-bold uppercase">Contact</h1>

            <ul className="mb-0 list-none">
            <li className="py-2">
                <a
                  href="mailto:backtobalance-festival@gmail.com"
                  className="text-white hover:text-[#4ec612]"
                >
                  Email
                </a>
              </li>
              <li className="py-2">
                <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=004368120500866" className="text-white hover:text-[#4ec612]">
                  Whatsapp
                </a>
              </li>
              <li className="py-2">
                <a target="_blank" rel="noreferrer" href="https://t.me/MoriElement" className="text-white hover:text-[#4ec612]">
                  Telegram
                </a>
              </li>
            </ul>
          </div>

          <div className="mb-6">
            <h1 className="mb-2.5 font-bold uppercase ">Company</h1>

            <ul className="mb-0 list-none">
            <li className="py-2">
                <a href="https://www.morielement.com" rel="noreferrer" target="_blank" className="text-white hover:text-[#4ec612]">
                  Organizer
                </a>
              </li>
              <li className="py-2">
                <a href="participate" className="text-white hover:text-[#4ec612]">
                  Participate
                </a>
              </li>
              <li className="py-2">
                <a href="#!" className="text-white hover:text-[#4ec612]">
                  Partners
                </a>
              </li>
            </ul>
          </div>

          <div className="mb-6">
            <h1 className="mb-2.5 font-bold uppercase">Legal</h1>

            <ul className="mb-0 list-none">
            <li className="py-2">
                <a href="impressum" target="_blank" rel="noreferrer" className="text-white hover:text-[#4ec612]">
                  Impressum
                </a>
              </li>
              <li className="py-2">
                <a href="privacy" className="text-white hover:text-[#4ec612]">
                  Privacy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="p-4 text-center"
        style={{ "backgroundColor": "rgba(0, 0, 0, 0.2)" }}
      >
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Back to Balance Festival. All rights
          reserved.
        </p>
        <a
          href="https://www.jawid.net/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm hover:text-red-800"
        >
          Website design & creation by Jawid
        </a>
      </div>
 
    </footer>
  );
};

export default Footer;

import about from "./about.jpg";
import acro from "./acro.jpg";
import area from "./area.jpeg";
import buddha from "./buddha.jpg";
import dance from "./dance.jpg";
import dance1 from "./dance1.jpg";
import dance2 from "./dance2.jpeg";
import dj from "./dj.jpg";
import drinks from "./drinks.jpg";
import exit from "./exit.jpg";
import face from "./face.jpg";
import gather from "./gather.jpg";
import girl from "./girl.jpg";
import handsup from "./handsup.jpeg";
import heal from "./heal.jpg";
import hug from "./hug.jpeg";
import kids from "./kids.jpg";
import laugh from "./laugh.jpg";
import laugh2 from "./laugh2.jpg";
import market from "./market.jpg";
import market1 from "./market1.jpg";
import mori from "./mori.jpg";
import mori1 from "./mori1.jpg";
import night from "./night.jpeg";
import sing from "./sing.jpg";
import sundance from "./sundance.jpg";
import workshop from "./workshop.jpg";
import yoga from "./yoga.jpg";
import yoga1 from "./yoga1.jpg";
import yoga2 from "./yoga2.jpg";
import yoga3 from "./yoga3.jpeg";
import calm from "./calm.jpg";
import video from "./video.mp4";
import dj2022 from "./2022-2.jpeg"
import laugh3 from "./2022-1.jpeg"
import b2b22 from "./2022.jpeg"

import a from "./1.jpg";
import b from "./2.jpg";
import c from "./3.jpg";
import d from "./4.jpg";
import e from "./5.jpg";
import f from "./6.jpg";
import g from "./7.jpg";
import h from "./8.jpg";
import i from "./9.jpg";
import j from "./10.jpg";
import k from "./11.jpg";
import l from "./12.jpg";
import n from "./13.jpg";
import o from "./14.jpg";
import p from "./15.jpg";
import q from "./16.jpg";
import r from "./17.jpg";
import s from "./18.jpg";
import t from "./19.jpg";
import u from "./20.jpg";
import v from "./21.jpg";
import w from "./22.jpg";
import x from "./23.jpg";
import gold from "./gold.webp"
import silver from "./silver.jpg"
import bronze from "./bronze.jpg"
import cbd from "./cbd.webp"
import spirit from "./spirit.webp"
import morielement from "./morielement.webp"
import curasana from "./curasana.webp"
import homewy from "./homewy.webp"
import usus from "./usus.webp"
import wings from "./wings.webp"
import flying from "./flying.webp"
import laura from "./laura.webp"
import machwerk from "./machwerk.webp"
import tosak from "./tosak.webp"
import dattel from "./dattel.webp"
import purjahan from "./purjahan.webp"

const images = {
  spirit,
  laugh3,
  b2b22,
  dj2022,
  purjahan,
  machwerk,
  tosak,
  dattel,
  a,
  b,
  c,
  d,
  e,
  f,
  g,
  h,
  i,
  j,
  k,
  l,
  n,
  o,
  p,
  q,
  r,
  s,
  t,
  u,
  v,
  w,
  x,
  gold,
  silver,
  bronze,

  video,

  about,
  acro,
  area,
  buddha,
  dance,
  dance1,
  dance2,
  dj,
  drinks,
  exit,
  face,
  gather,
  girl,
  handsup,
  heal,
  hug,
  kids,
  laugh,
  laugh2,
  market,
  market1,
  mori,
  mori1,
  night,
  sing,
  sundance,
  workshop,
  yoga,
  yoga1,
  yoga2,
  yoga3,
  calm,
  morielement,
  cbd,
  curasana,
  homewy,
  usus,
  wings,
  flying,
  laura
};

export default images;

import React, { useState } from "react";
import imagesArr from "../pages/imageArr";
import LazyLoad from "react-lazy-load";
import { AnimatePresence, motion } from "framer-motion";

const History = () => {
  const [expandedId, setExpandedId] = useState(null);

  const handleCardClick = (id) => {
    setExpandedId(id === expandedId ? null : id);
  };
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };


  return (
    <div  className="mx-auto flex w-full flex-col items-center justify-center rounded-xl  p-5 pb-20  pt-20 text-center">
      <div className="w-full max-w-screen-2xl rounded-xl px-4 pb-20 md:px-8 pt-20">
   
        <h2 className=" py-12 font-montserrat font-semibold rounded-full shadow-black shadow-2xl mb-10 hover:translate-y-2 events  hover:text-yellow-300 sm:text-xl md:text-2xl lg:text-4xl">
               Back to Balance Festival 2021-2022 - Vienna - Austria
           
            </h2> 
        <div className="grid grid-cols-2 gap-3   rounded-xl max-sm:p-2 p-5 md:grid-cols-3 lg:grid-cols-6 ">
          {imagesArr.map((image) => (
            <motion.div
              key={image.id}
              layoutId={image.id}
              onClick={() => handleCardClick(image.id)}
            >
              <a className="group relative flex h-48 border border-yellow-500  cursor-pointer items-center justify-center overflow-hidden  rounded-xl shadow-2xl md:h-80" role="link">
                <LazyLoad height={200} offset={300}>
                  <motion.img
                    src={image.src}
                    alt="image"
                    className="absolute inset-0 h-full w-full object-cover object-center  duration-300 group-hover:scale-110 group-hover:brightness-50"
                  />
                  
                </LazyLoad>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-90"></div>
                <AnimatePresence>
                  {expandedId === image.id && (
                    <div key="content">
                      <motion.div
                        key="content"
                        className="fixed inset-0 z-10  flex items-center justify-center bg-opacity-75 p-5 max-[500px]:m-5 max-[500px]:p-3 sm:p-5 md:p-10 lg:p-20  xl:p-24"
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                      >
                        <div className="absolute inset-0 h-full w-full ">
                          <LazyLoad height={200} offset={300}>
                            <img
                              className="absolute inset-0 h-full w-full object-cover"
                              src={image.src}
                              alt="Expanded Content Background"
                            />
                          </LazyLoad>
                        </div>
                      </motion.div>
                    </div>
                  )}
                </AnimatePresence>
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default History;

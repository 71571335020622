import React, { useState } from "react";
import programArray from "./programData";
import { motion } from "framer-motion";
import LazyLoad from 'react-lazy-load';


const Program = () => {
  const [expandedId, setExpandedId] = useState(null);

  // const handleCardClick = (id) => {
  //   setExpandedId(id === expandedId ? null : id);
  // };
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };



  
  return (
    <div className="mx-auto flex w-full flex-col items-center justify-center rounded-xl p-5 pb-20 pt-12 text-center">
      <div className="w-full max-w-screen-2xl rounded-xl px-4 py-20 md:px-8   ">
        <div className="mb-4 flex items-center justify-center font-semibold events shadow-black shadow-2xl p-10 rounded-full sm:mb-8 md:mb-12">
        <div className="mb-10 flex max-w-[1100px] flex-col items-center gap-12">
          
            <h2 className=" font-montserrat  hover:translate-y-2 hover:text-yellow-300 sm:text-xl md:text-2xl lg:text-4xl">
              Program
            </h2>
            
            <p className="text-md  font-montserrat sm:text-lg md:text-2xl font-bold">
            Welcome to our festival's program, a gateway to a world of wellness and transformation. Explore a diverse array of events and experiences that will nurture your mind, body, and soul. Join us in this celebration of self-discovery and healing as you embark on a path to inner harmony. Discover the magic that awaits you in each event, all designed to enrich your life and elevate your well-being.
            </p>
            
          </div>
          
        </div>
        

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {programArray.map((program) => (
            <motion.div
              key={program.id}
              layoutId={program.id}
              
            >
              <a className="group border border-yellow-500  relative flex h-48 items-center justify-center overflow-hidden rounded-full md:h-80 shadow-2xl shadow-black/20">
              <LazyLoad height={200} offset={300}>
                <motion.img
                  src={program.image}
                  alt="program"
                  className="absolute inset-0 h-full w-full object-cover  rounded-full object-center scale-90 duration-300 group-hover:scale-100 group-hover:brightness-50"
                />
                 </LazyLoad>

                <motion.h2
                  className="relative mb-3 ml-4 inline-block font-montserrat font-extrabold text-white group-hover:scale-110 group-hover:text-yellow-200 text-extrabold sm:text-lg md:text-xl lg:text-2xl "
                  initial={false}
                  animate={{ opacity: expandedId === program.id ? 0 : 1 }}
                >
                  {program.title}
                </motion.h2>
{/* 
                <AnimatePresence>
                  {expandedId === program.id && (
                    <div key="content">
                      <motion.div
                        key="content"
                        className="fixed inset-0 z-10 m-6 flex items-center justify-center bg-opacity-90 p-4 sm:m-10 sm:p-5 md:m-14 md:p-16 lg:m-20 lg:p-20 xl:m-24 xl:p-28 "
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                      >
                        <div className="absolute inset-0 h-full w-full  ">
                        <LazyLoad height={200} offset={300}>
                          <img
                            className="absolute inset-0 h-full w-full object-cover "
                            src={program.image}
                            alt="Expanded Content Background"
                          />
                          </LazyLoad>
                          <div className="absolute inset-0 flex justify-end bg-black opacity-90"></div>
                        </div>

                        <motion.h2 className="relative mb-3 inline-block justify-center text-center font-montserrat text-2xl text-white sm:text-xl md:ml-5 md:text-4xl">
                          {program.description}
                        </motion.h2>
                      </motion.div>
                    </div>
                  )}
                </AnimatePresence> */}
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Program;

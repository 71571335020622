import images from "../assets/images"

const imageArr = [
    {
        id: 1,
        src:images.a
    },
    {
        id: 2,
        src:images.b
    },
    {
        id: 3,
        src:images.c
    },
    {
        id: 4,
        src:images.d
    },
    {
        id: 6,
        src:images.e
    },
    {
        id: 7,
        src:images.f
    },
    {
        id: 8,
        src:images.g
    },
    {
        id: 9,
        src:images.h
    },
    {
        id: 10,
        src:images.i
    },
    {
        id: 11,
        src:images.j
    },
    {
        id: 12,
        src:images.k
    },
    {
        id: 13,
        src:images.l
    },
    {
        id: 15,
        src:images.n
    },
    {
        id: 16,
        src:images.o
    },
    {
        id: 17,
        src:images.p
    },
    {
        id: 18,
        src:images.q
    },
    {
        id: 19,
        src:images.r
    },
    {
        id: 20,
        src:images.s
    },
    {
        id: 21,
        src:images.t
    },
    {
        id: 22,
        src:images.u
    },
    {
        id: 23,
        src:images.v
    },
    {
        id: 24,
        src:images.w
    },
    {
        id: 25,
        src:images.x
    },
    {
        id: 26,
        src:images.laugh3
    },
    {
        id: 28,
        src:images.dj2022
    },
]

export default imageArr;
import React, { useState } from "react";
import images from "../assets/images";
import { AnimatePresence, motion } from "framer-motion";
import LazyLoad from "react-lazy-load";
import pastProgram from "./pastProgram";
import "react-slideshow-image/dist/styles.css";
import logo from "../assets/logo/Back To Balance Festival LOGO Black.png";

const programArray = [
  {
    id: 3,
    title: "Organizing Healing Area",
    time: "13-16. June 2024",
    description:
      "Back to Balance Welcomes you at Healing Area in Spirit Base Festival",
    image: images.x,
  },
];

const Events = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleCardClick = (id) => {
    setExpandedId(id === expandedId ? null : id);
  };
  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  return (
    <div className="mx-auto flex  flex-col items-center justify-center p-2 pb-20 pt-32 text-center">
      <div className=" max-w-screen-2xl rounded-xl px-2 pb-20 md:px-8  ">
        <div className="events flex flex-col items-center font-montserrat font-semibold justify-center rounded-full p-5 shadow-2xl shadow-black mb-20 ">
          <h2 className=" my-5 font-montserrat font-bold  hover:translate-y-2 hover:text-yellow-300 sm:text-xl md:text-2xl lg:mb-10 lg:text-4xl">
            Events
          </h2>

          <p className="pb-10  text-lg font-semibold  lg:px-20 lg:text-2xl">
            Welcome to ‘Back to Balance Festival’, organized by Mori Element, an
            expert in transformative event management. At the heart of our
            mission is to harmonize body, mind, and soul awareness. Explore a
            diverse range of experiences including yoga, meditation, workshops,
            dance, music, and more, all crafted to bring balance to urban life.
            Mori Elements specializes in tailoring events for companies and
            offers programs accessible to all, from the public to businesses.
            Stay tuned for workshops led by global experts, providing accredited
            certifications. Join us on this journey towards a more harmonious
            world.
          </p>
        </div>

        <div className="events mb-10  rounded-3xl p-5 shadow-lg shadow-black">
          <div className="py-5 text-xl">
            <div className=" grid grid-cols-1 pb-10 md:grid-cols-2 lg:grid-cols-3">
              <div className=" m-1 my-5  rounded-3xl bg-black/20 px-5 py-5 border border-yellow-500  shadow-lg shadow-black lg:mx-10  lg:px-10">
                <h1 className="pb-5">🧙🏻‍♂️ Variety of Workshops 🧙🏻‍♀️</h1>
                <p>
                  Savor sacred cocoa, connecting with inner self and collective
                  energy through rich ceremonial brew.
                </p>
              </div>
              <div className="m-1 my-5  rounded-3xl bg-black/20 px-5  py-5 border border-yellow-500  shadow-lg shadow-black lg:mx-10  lg:px-10">
                <h1 className="pb-5">🪩 Dance of the Soul ✨</h1>
                <p>
                  Transformative dance journey guided by rhythms, melodies, and
                  captivating facilitators.
                </p>
              </div>
              <div className=" m-1 my-5 rounded-3xl bg-black/20 px-5 py-5 border border-yellow-500  shadow-lg shadow-black lg:mx-10  lg:px-10">
                <h1 className="pb-5">🎵 Live Music Performances 🎵 </h1>
                <p>
                  immerse in enchanting live music, experience the magic of live
                  performances.
                </p>
              </div>
              <div className=" m-1 my-5 rounded-3xl bg-black/20 px-5 py-5 border border-yellow-500  shadow-lg shadow-black lg:mx-10  lg:px-10">
                <h1 className="pb-5"> 🛍️ A Unique Market 🎨 </h1>
                <p>
                  Explore handcrafted treasures, artisanal goods, and support
                  local artists in a one-of-a-kind market.
                </p>
              </div>
              <div className=" m-1 my-5 rounded-3xl bg-black/20 px-5 py-5 border border-yellow-500  shadow-lg shadow-black lg:mx-10  lg:px-10">
                <h1 className="pb-5"> 🌿 A Healing Space 🙏 </h1>
                <p>
                  Discover solace, meditate, and rejuvenate with guided energy
                  healing and holistic wellness.
                </p>
              </div>
              <div className=" m-1 my-5 rounded-3xl bg-black/20 px-5 py-5 border border-yellow-500 shadow-lg shadow-black lg:mx-10  lg:px-10">
                <h1 className="pb-5"> 🎧 A Sensational DJ 🎶 </h1>
                <p>
                  Dance the night away to electrifying beats for an
                  unforgettable evening of music and dance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="events grid grid-cols-1 gap-2 rounded-3xl bg-black/20 p-10 sm:grid-cols-2 md:grid-cols-3 md:px-10 lg:grid-cols-4 ">
          <h1 className="text-3xl font-bold">Past Events</h1>
          {pastProgram.map((program) => (
            <motion.div
              key={program.id}
              layoutId={program.id}
              onClick={() => window.open(program.link)}
            >
              <a className="group relative flex border border-yellow-500  h-48 cursor-pointer items-center justify-center overflow-hidden rounded-xl shadow-2xl md:h-80">
                <LazyLoad height={200} offset={300}>
                  <motion.img
                    src={program.image}
                    alt="program"
                    className="absolute inset-0 h-full w-full object-cover object-center  duration-300 group-hover:scale-110 group-hover:brightness-50"
                  />
                </LazyLoad>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-90"></div>
                <motion.h2
                  className="text-extrabold relative mb-3 ml-4 inline-block font-montserrat font-extrabold text-white group-hover:scale-110 group-hover:text-yellow-200 sm:text-lg md:text-xl lg:text-2xl "
                  initial={false}
                  animate={{ opacity: expandedId === program.id ? 0 : 1 }}
                >
                  {program.title}
                </motion.h2>
              </a>
            </motion.div>
          ))}
        </div>
        <div className="events mt-10 grid grid-cols-1 gap-2 rounded-3xl bg-black/20 p-10  sm:grid-cols-2 md:grid-cols-3 md:px-10 lg:grid-cols-4 ">
          <h1 className="text-3xl font-bold">Future Events</h1>
          {programArray.map((program) => (
            <motion.div
              key={program.id}
              layoutId={program.id}
              onClick={() => window.open("https://www.facebook.com/SpiritBaseFestival/",'_blank')}
            >
              <a className="group border border-yellow-500  relative flex h-48 cursor-pointer items-center justify-center overflow-hidden rounded-xl shadow-2xl md:h-80">
                <LazyLoad height={300} offset={300}>
                  <motion.img
                    src={images.spirit}
                    alt="program"
                    className="absolute inset-0 h-full w-full scale-100 object-contain object-center  duration-300  group-hover:brightness-50"
                  />
                 
                </LazyLoad>
                <LazyLoad height={300} offset={300}>
                  <motion.img
                    src={logo}
                    alt="program"
                    className="absolute inset-0 h-full w-full scale-75 opacity-40 object-contain object-center  duration-300 group-hover:scale-100 group-hover:brightness-50 group-hover:opacity-90"
                  />
                 
                </LazyLoad>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-90"></div>
                <motion.h2
                  className="text-extrabold relative mb-3 ml-4 inline-block font-montserrat font-extrabold text-white group-hover:scale-110 group-hover:text-yellow-200 sm:text-lg md:text-xl lg:text-2xl "
                  initial={false}
                  animate={{ opacity: expandedId === program.id ? 0 : 1 }}
                >
                  {program.title}
                </motion.h2>

                <AnimatePresence>
                  {expandedId === program.id && (
                    <div key="content">
                      <motion.div
                        key="content"
                        className="w fixed inset-0 z-10 flex items-center justify-center bg-opacity-90 p-5 md:p-20 "
                        variants={dropIn}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                      >
                        <div className="absolute inset-0 h-full w-full  ">
                          <LazyLoad height={200} offset={300}>
                            <img
                              className="absolute inset-0 h-full w-full object-cover "
                              src={program.image}
                              alt="Expanded Content Background"
                            />
                          </LazyLoad>
                          <div className="absolute inset-0 flex justify-end bg-black opacity-90"></div>
                        </div>

                        <motion.div className="relative mb-3 inline-block justify-center text-center font-montserrat text-lg text-white  md:ml-5 ">
                          <h1>🌟 Welcome to Back to Balance 🌟</h1>
                          <div className="py-5">
                            <p className="py-4">🌍 Date: {program.time}</p>
                            
                            {/* <p className="py-4">
                                🍹 Enjoy your complimentary welcome drink and
                                snack.
                              </p> */}
                          </div>
                          {/* <div className="flex  flex-col gap-5 py-5 ">
                              <p>
                                🎵 7:00 PM - 8:00 PM: Live Band Performance &
                                Cacao Ceremony
                              </p>
                              <p>🌟 🎧 8:00 PM - 10:00 PM: Live DJ Set</p>
                              <p>💃 8:00 PM - 10:00 PM: Ecstatic Dance</p>
                              <p>🔮 10:00 PM - 10:15 PM: Sound Healing</p>
                              <p> A moment of serenity to close the evening.</p>
                            </div> */}
                          {/* <button className="delay-50 hover:-translate mt-5 rounded-full bg-amber-100 px-4 py-2 font-montserrat font-bold text-amber-800 transition duration-300 ease-in-out hover:bg-[#c7b654]">
                            Tickets available soon{" "}
                          </button> */}
                        </motion.div>
                      </motion.div>
                    </div>
                  )}
                </AnimatePresence>
              </a>
            </motion.div>
          ))}
        </div>
        <div className="events mt-10  flex max-lg:flex-col rounded-3xl bg-black/20 p-5 lg:p-10 ">
          <div className="flex flex-col gap-5 text-start">
            <h1 className="text-3xl font-bold ">Business Events</h1>
            <p className=" lg:pr-20 font-semibold pt-8 leading-relaxed">
              Transform your company with our tailored corporate program
              offering weekly yoga, massages, and transformative sessions.
              Choose from Gold, Silver, or Bronze packages to bring balance and
              awareness to your business. Inquire now for pricing and seize the
              opportunity to enhance your company’s well-being.
            </p>
          </div>
          <div className="w-full rounded-xl px-4 py-20 max-sm:px-0 md:px-8">
            <div className="max-md:flex-col flex flex-row items-center justify-center gap-20 text-center">
              {showPopup && (
                <div className="homepage fixed top-0 left-0 z-50 hidden h-full w-full items-center justify-center rounded-xl px-10 backdrop-blur-3xl transition-all duration-500 ease-in-out  hover:bg-[#20202066] lg:flex  ">
                  <div className="popup">
                    {/* Content for the popup */}

                    <iframe
                      src="https://form.jotform.com/232046140869052"
                      title="Typeform Popup"
                      width={1200}
                      height={700}
                      className="pt-10"
                    />
                    <button
                      className=" delay-50 hover:-translate mb-20 mt-5 rounded-full bg-amber-100 px-4 py-2 font-montserrat font-bold text-amber-800 transition duration-300 ease-in-out hover:scale-125 hover:bg-green-400"
                      onClick={closePopup}
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
              {showPopup && (
                <div className="popup fixed left-0 top-0 z-50 h-full w-full items-center justify-center bg-black bg-opacity-40 py-32 backdrop-blur-xl lg:hidden   ">
                  {/* Content for the popup */}

                  <iframe
                    src="https://form.jotform.com/232046140869052"
                    title="Typeform Popup"
                    className="h-full w-full"
                  />
                  <button
                    className=" delay-50 hover:-translate mb-20 mt-5 rounded-full bg-amber-100 px-4 py-2 font-montserrat font-bold text-amber-800 transition duration-300 ease-in-out hover:scale-125 hover:bg-green-400"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                </div>
              )}
              <div className="group h-60 w-60  font-montserrat ">
                <div className="relative flex h-full w-full lg:flex-row rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                  <div className="absolute inset-0 ">
                    <img
                      className="h-full w-full border border-yellow-500  rounded-xl object-cover  shadow-xl shadow-black/40"
                      src={images.bronze}
                      alt="silver"
                    />
                  </div>
                  <div className="absolute inset-0 h-full w-full rounded-xl bg-white/50 px-12 text-center text-slate-200 [backface-visibility:hidden] [transform:rotateY(180deg)]">
                    <div className="flex min-h-full flex-col items-center  justify-center">
                      <button
                        onClick={openPopup}
                        className="delay-50 hover:-translate mt-2 rounded-full bg-yellow-100 px-4 font-montserrat font-bold text-gray-950 transition duration-300 ease-in-out hover:scale-125 hover:bg-[#f7cf40]"
                      >
                        Book
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group h-60 w-60  font-quicksand ">
                <div className="relative grid h-full w-full grid-cols-3 rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                  <div className="absolute inset-0">
                    <img
                      className="h-full w-full rounded-xl border border-yellow-500  object-cover shadow-xl shadow-black/40"
                      src={images.silver}
                      alt="silver"
                    />
                  </div>
                  <div className="absolute inset-0 h-full w-full rounded-xl bg-white/50 px-12 text-center text-slate-200 [backface-visibility:hidden] [transform:rotateY(180deg)] ">
                    <div className="flex min-h-full flex-col items-center justify-center">
                      <button
                        onClick={openPopup}
                        className="delay-50 hover:-translate mt-2 rounded-full bg-yellow-100 px-4 font-montserrat font-bold text-gray-950 transition duration-300 ease-in-out hover:scale-125 hover:bg-[#f7cf40]"
                      >
                        Book
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group h-60 w-60  font-montserrat">
                <div className="relative grid h-full w-full grid-cols-3 rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
                  <div className="absolute inset-0">
                    <img
                      className="h-full w-full rounded-xl border border-yellow-500  object-cover shadow-xl shadow-black/40"
                      src={images.gold}
                      alt="gold"
                    />
                  </div>
                  <div className="absolute inset-0 h-full w-full rounded-xl bg-white/50 px-12 text-center text-slate-200 [backface-visibility:hidden] [transform:rotateY(180deg)]">
                    <div className="flex min-h-full flex-col items-center justify-center">
                      <button
                        onClick={openPopup}
                        className="delay-50 hover:-translate mt-2 rounded-full bg-yellow-100 px-4 font-montserrat font-bold text-gray-950 transition duration-300 ease-in-out hover:scale-125 hover:bg-[#f7cf40]"
                      >
                        Book
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;

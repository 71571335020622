import images from "../assets/images"

const pastProgram = [
    {
        id:1,
        title: "",
        link:"https://www.youtube.com/watch?v=AMaC_70m-iE",
        image:images.b2b22
    },
    {
        id:2,
        title: "09.09.2020",
        link:"https://www.youtube.com/watch?v=8JwkY9Oz6DE",
        image:images.yoga
    }

]

export default pastProgram;
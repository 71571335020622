import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { CartContext } from '../Store/CartContext';
import { useContext } from 'react';
import images from "../assets/images";

function ProductCard(props) { // props.product is the product we are selling
    const product = props.product;
    const cart = useContext(CartContext);
    const productQuantity = cart.getProductQuantity(product.id);
    return (
        <Card className="bg-dark text-white" >
            <Card.Img variant="top" src={images.logo} />
            <Card.Body>
                <Card.Title >{product.title}</Card.Title>
                <Card.Text >€ {product.price}</Card.Text>
                { productQuantity > 0 ?
                    <>
                        <Form as={Row}>
                            <Form.Label column="true" sm="6">In Cart: {productQuantity}</Form.Label>
                            <Col sm="6">
                                <Button variant='dark' onClick={() => cart.addOneToCart(product.id)} >+</Button>
                                <Button variant='dark' onClick={() => cart.removeOneFromCart(product.id)}  >-</Button>
                            </Col>
                        </Form>
                        <Button  variant='danger' onClick={() => cart.deleteFromCart(product.id)} >Remove from cart</Button>
                    </>
                    :
                    <Button variant="warning" onClick={() => cart.addOneToCart(product.id)}>Add To Cart</Button>
                }
            </Card.Body>
        </Card>
    ) 
}

export default ProductCard;
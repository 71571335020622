import React, { useState } from "react";
import images from "../assets/images";


const Business = () => {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // const backgroundImageStyle = {
  //   backgroundImage: `url(${bg})`, // Replace with your image path
  //   backgroundSize: "cover",
  //   backgroundRepeat: "no-repeat",
  // };
  return (
    <div  className="mx-auto flex w-full  flex-col max-sm:px-0 items-center justify-center rounded-xl bg-black bg-opacity-50 p-5 pb-20 pt-20 text-center">
            {showPopup && (
        <div className="homepage hidden z-50 rounded-xl px-10 fixed top-0 h-full w-full items-center justify-center lg:flex backdrop-blur-3xl transition-all duration-500  ease-in-out hover:bg-[#20202066]  ">
          <div className="popup">
            {/* Content for the popup */}

            <iframe
              src="https://form.jotform.com/232046140869052"
              title="Typeform Popup"
              width={1200}
              height={700}
              className="pt-10"
            />
            <button
              className=" delay-50 hover:-translate mb-20 mt-5 rounded-full bg-amber-100 px-4 py-2 font-montserrat font-bold text-amber-800 transition duration-300 ease-in-out hover:scale-125 hover:bg-green-400"
              onClick={closePopup}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {showPopup && (
      
          <div className="popup w-full fixed left-0 lg:hidden top-0 py-32 z-50 h-full items-center justify-center backdrop-blur-xl bg-black bg-opacity-40   ">
            {/* Content for the popup */}

            <iframe
              src="https://form.jotform.com/232046140869052"
              title="Typeform Popup"
              className="w-full h-full"
            />
            <button
              className=" delay-50 hover:-translate mb-20 mt-5 rounded-full bg-amber-100 px-4 py-2 font-montserrat font-bold text-amber-800 transition duration-300 ease-in-out hover:scale-125 hover:bg-green-400"
              onClick={closePopup}
            >
              Close
            </button>
          </div>
        
      )}
      <h2 className=" py-12 mb-10 font-quicksand text-white hover:translate-y-2 hover:text-yellow-300 sm:text-xl md:text-2xl lg:text-4xl">
              <hr /> Private Events
              <hr />
              </h2>
     <div className="max-w-[1300px] px-5">

              <p className="text-md mb-10 font-quicksand sm:text-lg md:text-2xl">Experience our festival in a more personalized and exclusive way with our Private Event Packages. These packages are designed to elevate your festival experience, providing a range of benefits and opportunities for both individuals and teams.</p>
     </div>
      <div className="w-full rounded-xl px-4 py-20 md:px-8 max-sm:px-0">
    

        <div className="flex flex-row max-[1000px]:flex-col items-center justify-center gap-20 text-center max-mds:flex-col">
          <div className="group h-96 w-96 max-sm:w-90 font-montserrat [perspective:1000px] ">
            <div className="relative flex h-full w-full flex-row rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
              <div className="absolute inset-0 ">
                <img
                  className="h-full w-full rounded-xl object-cover  shadow-xl shadow-black/40"
                  src={images.bronze}
                  alt="silver"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="font-montserrat font-bold text-3xl animate-pulse text-black">
                    Bronze Package
                  </span>

                </div>
              </div>
              <div className="absolute inset-0 h-full w-full rounded-xl bg-black/90 px-12 text-center text-slate-200 [backface-visibility:hidden] [transform:rotateY(180deg)]">
                <div className="flex min-h-full flex-col items-center  justify-center">
                 
                  <ul className="list-disc text-left ">
                    <li>Full day Program (11-22 h)</li>
                    
                    <li>Yoga, Workshops, Healing Area, Evening Program</li>
                    <li>Decoration, Sound System, Security Team included</li>
                    <li>Anonymous feedback-form</li>
                  </ul>
              
                  <button onClick={openPopup} className="delay-50 hover:-translate text-gray-950 font-montserrat font-bold rounded-full bg-yellow-100 px-4 mt-32 transition duration-300 ease-in-out hover:scale-125 hover:bg-[#c58f64]">
                    Book
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="group h-96 w-96 max-sm:w-90 font-quicksand [perspective:1000px]">
            <div className="relative grid h-full w-full grid-cols-3 rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40"
                  src={images.silver}
                  alt="silver"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="font-montserrat font-bold text-3xl animate-pulse text-black">
                    Silver Package
                  </span>
                </div>
              </div>
              <div className="absolute inset-0 h-full w-full rounded-xl bg-black/90 px-12 text-center text-slate-200 [backface-visibility:hidden] [transform:rotateY(180deg)] ">
                <div className="flex min-h-full flex-col items-center justify-center">
                  
                  <ul className="list-disc text-left">
                    <li>Full day Program (11-22 h)</li>
                    
                    <li>Yoga, Workshops, Healing Area, Evening Program</li>
                    
                    <li>Cocoa ceremony, ecstatic dance, child care, market.</li>
                    <li>Themed "goodie bag</li>
                    <li>Decoration, Sound System, Security Team included</li>
                    <li>Anonymous event feedback survey</li>
                  </ul>
                  <button
                 onClick={openPopup}
                  className="delay-50 hover:-translate text-gray-950 font-montserrat font-bold rounded-full bg-yellow-100 px-4 mt-16 transition duration-300 ease-in-out hover:scale-125 hover:bg-[#c0c0c0]">
                    Book
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="group h-96 w-96 max-sm:w-90 font-montserrat [perspective:1000px]">
            <div className="relative grid h-full w-full grid-cols-3 rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40"
                  src={images.gold}
                  alt="gold"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="font-montserrat font-bold text-3xl animate-pulse text-black">
                    Golden Package
                  </span>
                </div>
              </div>
              <div className="absolute inset-0 h-full w-full rounded-xl bg-black/90 px-12 text-center text-slate-200 [backface-visibility:hidden] [transform:rotateY(180deg)]">
                <div className="flex min-h-full flex-col items-center justify-center">
                  
                  <ul className="list-disc text-left">
                    <li className="">Full day Program (11-22 h)</li>
                    
                    <li>Yoga, Workshops, Healing Area, Evening Program</li>
                    
                    <li>Cocoa ceremony, ecstatic dance, child care, market,
30-minute fire show & surprise live concert in the evening</li>
                    <li>Professional photo & video coverage</li>
                    <li>Theme-related "goodie bag" + commemorative shirt</li>
                    <li>Decoration, Sound System, Security Team included</li>
                    <li>Anonymous event feedback survey</li>
                  </ul>
                  <button
                 onClick={openPopup}
                  className="delay-50 hover:-translate text-gray-950 font-montserrat font-bold rounded-full bg-yellow-100 px-4 mt-2 transition duration-300 ease-in-out hover:scale-125 hover:bg-[#f7cf40]">
                    Book 
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-10 flex items-center justify-center  sm:mb-8 ">
          <div className="flex shadow-2xl shadow-black rounded-2xl bg-black bg-opacity-50 m-10 p-10 max-sm:m-2 max-sm:p-8 flex-col items-center gap-12">
            
            <h2 className="font-montserrat text-white hover:translate-y-2 hover:text-yellow-300 sm:text-xl md:text-2xl lg:text-4xl">
            ADVANTAGES OF THE PRIVAT EVENT
            </h2>
          <ul className="list-disc text-left font-montserrat">
            <li>Creation of team spirit and sense of community</li>
            <li>Strengthening of the working atmosphere</li>
            <li>Increase in performance and employee motivation</li>
            <li>Reduction of internal communication barriers</li>
            <li>Employee loyalty and retention</li>
            <li>Appreciation and reward for your employees</li>
            <li>Health promotion of your valuable MA</li>
            <li>Satisfied employees</li>
            <li>Sustainable more fun at work</li>
            
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;

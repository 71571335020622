import images from "../assets/images"

const programArray = [
    {
        id:1,
        title: "Opening Ceremony",
        description: "The Opening Ceremony is a magical moment. As we gather together, we feel a sense of excitement and anticipation. The ceremony is a time to connect with others, set intentions, and open our hearts to the transformative power of yoga and other healing practices. Led by spiritual leaders and teachers, the ceremony is a reminder that the journey towards health and wellness is also about spiritual and emotional well-being. We leave the ceremony with a sense of connection and purpose, ready to embark on a journey of self-discovery and transformation.",
        image:images.mori
    },
    {
        id:2,
        title: "Yoga Vinyasa Flow",
        description: "Vinyasa Flow yoga is a style of yoga that links breath with movement. It is a dynamic and flowing practice that can be physically challenging. The word vinyasa means to place in a special way, and in this style of yoga, students flow through a sequence of postures, moving with the breath. The sequence of postures is often referred to as a flow The practice is designed to build strength, flexibility, and mindfulness, and can be tailored to suit different levels of experience.",
        image:images.yoga
    },
    {
        id:3,
        title: "Cacao Ceremony",
        description: "A cacao ceremony is a powerful spiritual experience. When consumed intentionally, the warm and soothing cacao can transport you to a realm where you can connect deeply with yourself and others. It has the ability to open your heart and allow you to release emotions that have been suppressed for too long. Participating in a cacao ceremony can help you feel more grounded, centered, and connected to the world.",
        image:images.handsup
    },
    {
        id:4,
        title: "Self Love Workshop",
        description: "A self-love workshop is a transformative event that can help you learn to love yourself more deeply. Through guided meditations, journaling, and group discussions, you can explore your feelings and beliefs about yourself, and cultivate a more positive self-image. This is a safe and supportive space for you to connect with others on a journey of self-discovery and self-love. Join us and start your journey toward greater self-love today.",
        image:images.workshop
    },
    {
        id:5,
        title: "Conscious Breathing Workshop",
        description: "Conscious breathing is a powerful tool for connecting with the present moment and calming the mind. It allows you to focus on your breath, bringing your attention inward and bringing a sense of peace and clarity. When you take a deep breath and tune out the distractions of the world, you can tap into your inner wisdom and connect with your emotions. Conscious breathing can be a catalyst for transformation, helping you to release negative thoughts and emotions and embrace a more positive outlook on life. Take a moment to breathe deeply and feel the power of conscious breathing for yourself.",
        image:images.h
    },
    {
        id:6,
        title: "Tantric Inner Alchemy",
        description: "Tantric Inner Alchemy is a spiritual practice that involves harnessing the energy of the body to achieve a greater sense of connection and awareness. It is a powerful tool for transformation, helping individuals to release negative energy and emotions and tap into their inner wisdom. Through the practice of Tantric Inner Alchemy, individuals can cultivate a deeper understanding of themselves and the world around them, and connect with their inner divine power. This practice can lead to a greater sense of joy, peace, and fulfillment in life.",
        image:images.sundance
    },
    
    {
        id:7,
        title: "Soundhealing Session",
        description: "Sound healing uses the power of sound vibrations to promote physical, emotional, and spiritual healing. It's a transformative practice that can help reduce stress and anxiety, restore balance and harmony, and connect you with the healing power of sound. With the use of singing bowls, gongs, and chimes, sound healing can help you tap into your body's natural ability to heal itself.",
        image:images.buddha
    },
    {
        id:8,
        title: "Liberation Dance",
        description: "Liberation Dance is a form of movement therapy that encourages individuals to connect with their bodies and express themselves through dance. It is a powerful tool for healing and transformation, allowing individuals to release negative emotions and connect with their inner truth. The practice of Liberation Dance is rooted in the belief that movement can be a catalyst for change, helping individuals to break free from old patterns and embrace a more authentic way of being. Through this practice, individuals can cultivate a deeper sense of self-awareness and connect with their inner wisdom. Liberation Dance is a celebration of the human spirit and the power of movement to heal and transform.",
        image:images.gather
    },
    {
        id:9,
        title: "Contact Improvisation Dance",
        description: "Contact Improvisation is a dance form that celebrates physical connection and spontaneity. It's about trusting yourself, trusting others, and letting your body move you. Through this practice, you can cultivate a deeper sense of self-awareness and connect with your creative spirit. Contact Improvisation is a celebration of the human body and its ability to communicate and create through movement.",
        image:images.dance2
    },
    {
        id:10,
        title: "Child Care With Kids Yoga",
        description: "Kids yoga is a wonderful opportunity for children to play, learn, and explore in a safe and nurturing environment. Kids yoga helps children to develop self-confidence, self-awareness, and empathy towards others. It's a great way to introduce children to the joys of yoga and mindfulness while parents can enjoy the festival knowing their children are in good hands.",
        image:images.kids
    }
    ,
    {
        id:11,
        title: "Live Music - Dj Set",
        description: " Get ready for an electrifying experience as we bring you an incredible lineup of live music performances that will leave you mesmerized. From chart-topping headliners to talented local artists, this festival is a celebration of diverse sounds and unforgettable melodies. So, put on your dancing shoes and let the music guide you through an unforgettable journey.",
        image:images.dance
    }
]

export default programArray;
import React from 'react'

const Impressum = () => {
  return (
    <div className="text-white mx-auto text-center justify-center bg-black bg-opacity-50 p-10 items-center flex pt-[96px] " id='impressum'>
      <div className=" max-w-[1200] rounded-2xl flex flex-col text-center">
      
    
        <h1 className="hover: text-4xl hover:translate-y-2 hover:text-yellow-300 sm:text-6xl md:py-6 md:text-7xl font-montserrat">
          Back to Balance
        </h1>
        <p className="text-md my-16 font-quicksand">
          Impressum <br /> <br />
          Morteza Ketabnevis <br />
          Engerthstraße 221/29 <br />
          1020 Wien <br />
          <br />
          Kontaktdaten: <br />
          Telefon +43 681 20500866 <br />
          E-Mail: backtobalance.festival@gmail.com <br />
          UID: keine (Kleinunternehmer) <br />
          <br />
          Unternehmensgegenstand : <br />
          Organisation von Veranstaltungen, Märkten und Messen <br />
          GISA-Zahl: 34876527 <br />
          Berufsbezeichnung: Eventmanagement <br />
          <br />
          Mitglied der Wirtschaftskammer Wien <br />
          Anwendbare Rechtsvorschriften und Zugang dazu: Gewerbeordnung{" "}
          <a href="http://www.ris.bka.gv.at">www.ris.bka.gv.at</a>
          <br /> Gewerbebehörde: Magistrat der Stadt Wien für den 2./20. Bezirk
        </p>
      </div>
     
    </div>
  )
}

export default Impressum